import * as React from 'react'

import { Info } from '@mui/icons-material'
import {
  Container,
  Link,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { graphql } from 'gatsby'

import {
  IconCard,
  ListItem,
  OrderedList,
  PercentageCell,
  Section,
  SectionWrapper,
  SeoHeaders,
  SeoImage,
  Subtitle,
  Table,
  Title,
  TokenCell,
  UnorderedList,
} from 'shared/components'
import { mapBaseApys } from 'shared/services'

import { cryptoData } from '../data/crypto'
import BitcoinCircuit from '../images/landing/bitcoin-circuit.svg'
import EthereumMesh from '../images/landing/ethereum-mesh.svg'
import Layout from '../layouts/landing'

import type { BlockchainName, CryptoName } from '../data/crypto'
import type { PageProps } from 'gatsby'
import type { BaseApy } from 'shared/queries'
import type { BaseApysMap } from 'shared/services'

type MarketAsset = {
  id: string
  name: CryptoName
  symbol: string
}

type QueryProps = {
  guita: {
    marketAssets: MarketAsset[]
    baseApys: BaseApy[]
  }
}

export const query = graphql`
  query AboutMarketAssets {
    guita {
      marketAssets {
        id
        name
        symbol
      }
      baseApys {
        id
        marketAssetId
        value
      }
    }
  }
`

const ApyTable = ({
  marketAssets,
  baseApys,
}: { marketAssets: MarketAsset[]; baseApys: BaseApysMap }) => (
  <TableContainer
    component={Paper}
    elevation={12}
  >
    <Table aria-label='tabla APY'>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align='right'>APY Actual</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {marketAssets.map((asset) => (
          <TableRow key={asset.name}>
            <TokenCell
              name={asset.name}
              symbol={asset.symbol}
            />
            <PercentageCell value={baseApys[asset.id]} />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)

const BlockchainsCell = ({
  blockchains,
}: { blockchains: BlockchainName[] }) => (
  <TableCell
    padding='none'
  >
    {blockchains.map((blockchain, index) => (
      <Typography
        key={index}
        variant='body2'
        component='span'
        sx={{
          display: 'block',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          py: 1,
          px: 2,
          borderBottom: (index !== blockchains.length - 1) ? 1 : 0,
          borderBottomColor: 'action.focus',
        }}
      >
        {blockchain}
      </Typography>
    ))}
  </TableCell>
)

const FeesTable = ({
  marketAssets,
}: { marketAssets: MarketAsset[] }) => (
  <TableContainer
    component={Paper}
    elevation={12}
  >
    <Table aria-label='tabla de fees'>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2} />
          <TableCell colSpan={3}>
            Depósitos
          </TableCell>
          <TableCell colSpan={3}>
            Retiros
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Token</TableCell>
          <TableCell>Network</TableCell>
          <TableCell>Fee de depósito</TableCell>
          <TableCell>Depósito mínimo</TableCell>
          <TableCell>Depósito máximo</TableCell>
          <TableCell>Fee de retiro</TableCell>
          <TableCell>Retiro mínimo</TableCell>
          <TableCell>Retiro máximo</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {marketAssets.map((asset) => (
          <TableRow key={asset.name}>
            <TokenCell
              name={asset.name}
              symbol={asset.symbol}
            />
            <BlockchainsCell blockchains={cryptoData[asset.name].blockchains} />
            <TableCell align='center'>$0</TableCell>
            <TableCell align='center'>Sin&nbsp;mínimo</TableCell>
            <TableCell align='center'>Sin&nbsp;máximo</TableCell>
            <TableCell align='center'>US$2,00 <small>+&nbsp;NETWORK&nbsp;FEE</small></TableCell>
            <TableCell align='center'>Sin&nbsp;mínimo</TableCell>
            <TableCell align='center'>Sin&nbsp;máximo</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)

const AboutPage = ({ data: { guita } }: PageProps<QueryProps>) => (
  <Layout>
    <SeoHeaders
      title='¿Qué es Sugarblock?'
      description={(
        'Ayudamos a gestionar tus criptomonedas de forma segura mediante'
        + ' una plataforma robusta que además te genera ingresos pasivos.'
      )}
      schemaMarkup={{
        '@context': 'https://schema.org',
        '@type': 'Organization',
        'name': 'Sugarblock',
        'url': 'https://sugarblock.io',
        'description': 'Custodia y rentabilidad de criptomonedas. Compra, transfiere y protege tu bitcoin,' +
     'ethereum, cardano, polkadot y stablecoins. Seguridad y blockchain.',
        'logo': 'https://sugarblock.io/images/mail-header.png',
        'contactPoint': [
          {
            '@type': 'ContactPoint',
            'telephone': '+56998182767',
            'contactType': 'customer service',
          },
        ],
        'email': 'satoshi@sugarblock.io',
        'sameAs': [
          'https://www.linkedin.com/company/sugarblock/',
          'https://www.instagram.com/sugarblock.io/?hl=en',
        ],
        'address': {
          '@type': 'PostalAddress',
          'streetAddress': 'San Patricio 4298 Of 11',
          'addressLocality': 'Santiago',
          'addressRegion': 'Region Metropolitana',
          'postalCode': '7630273',
          'addressCountry': 'Chile',
        },
      }}
    />
    <Container maxWidth='md'>
      <SectionWrapper>
        <Section>
          <Title
            mainTitle
            textAlign='center'
          >
            ¿Qué es Sugarblock?
          </Title>
          <Subtitle
            fontWeight={300}
            component='p'
          >
            Sugarblock es una cuenta de ahorro en criptomonedas y dólares digitales. Es tu puerta de acceso
            al mundo cripto de forma simple e intuitiva. Al usar Sugarblock, comienzas a generar recompensas
            de forma completamente automatizada.
          </Subtitle>
        </Section>
        <Section>
          <Title
            id='apy'
            textAlign='center'
          >
            Recompensas estimadas
          </Title>
          <ApyTable
            marketAssets={guita.marketAssets}
            baseApys={mapBaseApys(guita.baseApys)}
          />
          <Paper
            variant='outlined'
            sx={{ p: 2, pb: 3, bgcolor: 'action.hover' }}
          >
            <Subtitle
              textAlign='center'
              fontWeight={400}
              paddingBottom={1}
            >
              Definiciones
            </Subtitle>
            <UnorderedList>
              <ListItem title='Annual Percentage Yield (APY)'>
                Rentabilidad compuesta anual generada con un activo.
              </ListItem>
              <ListItem title='APY Estimado'>
                Recompensa estimada que recibirás con sólo depositar en tu cuenta Sugarblock.
              </ListItem>
            </UnorderedList>
          </Paper>
          <IconCard
            icon={<Info />}
            color='primary.contrastText'
            bgcolor='primary.dark'
          >
            Puedes encontrar más información sobre recompensas en nuestra <Link
              href='/faq/#interest-rates'
              color='inherit'
              // eslint-disable-next-line react/jsx-closing-bracket-location
            >
              página de preguntas frecuentes
            </Link>.
          </IconCard>
        </Section>
      </SectionWrapper>
    </Container>
    <Container maxWidth='lg'>
      <SectionWrapper>
        <Section>
          <Title
            id='fees'
            textAlign='center'
          >
            Fees de Depósitos y Retiros
          </Title>
          <FeesTable marketAssets={guita.marketAssets} />
          <IconCard
            icon={<Info />}
            color='primary.contrastText'
            bgcolor='primary.dark'
          >
            Puedes encontrar más información sobre depósitos y retiros en nuestra <Link
              href='/faq/#withdrawals-and-deposits'
              color='inherit'
              // eslint-disable-next-line react/jsx-closing-bracket-location
            >
              página de preguntas frecuentes
            </Link>.
          </IconCard>
        </Section>
      </SectionWrapper>
    </Container>
    <Container maxWidth='md'>
      <SectionWrapper textAlign='justify'>
        <SeoImage
          src={BitcoinCircuit}
          alt='Bitcoin circuit'
          height='240px'
          shift='top'
        />
        <Section>
          <Title
            textAlign='center'
          >
            ¿Para qué sirve Sugarblock?
          </Title>
          <Subtitle
            textAlign='center'
            fontWeight={300}
            color='text.secondary'
          >
            Protegemos y crecemos la riqueza digital de nuestros usuarios.
          </Subtitle>
          <Subtitle>
            Sugarblock te permite:
          </Subtitle>
          <OrderedList>
            <ListItem title='Guardar tus criptomonedas en una plataforma segura' />
            <ListItem title='Acumular recompensas con tus criptomonedas' />
            <ListItem title='Comprar criptomonedas mediante tarjeta de crédito o transferencia bancaria*' />
            <ListItem title='Gastar tus criptomonedas cuando quieras*' />
          </OrderedList>
          <IconCard
            icon={<Info />}
            color='primary.contrastText'
            bgcolor='primary.dark'
          >
            * Disponibilidad dependiente del país de residencia.
          </IconCard>
        </Section>
        <SeoImage
          src={EthereumMesh}
          alt='Ethereum mesh'
          height='240px'
          shift='top'
        />
      </SectionWrapper>
    </Container>
  </Layout>
)

export default AboutPage
